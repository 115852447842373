/*
===============================|> AUTH APIS <|===============================
01 |--> Login Api
02 |--> Register Api
03 |--> reset password:
|--> send email 
|--> send otp api and verify otp 
|--> change password
===============================|> AUTH APIS <|===============================
*/

import axios from '@/utils/axios-config';

// 01 --> Login api
const logIn = async (handshake, username, password) => {
  const data = JSON.stringify({ username, password });
  const config = {
    method: 'post',
    url: '/customer/login',
    headers: {
      Authorization: `Bearer ${handshake}`,
    },
    data,
  };
  console.log('login config', config);
  try {
    const req = await axios(config);
    console.log('login success', req);
    return req;
  } catch (error) {
    return error;
  }
};

// 02 --> Register api
const register = async (
  handshake,
  { email, firstname, lastname, password, mobile }
) => {
  const data = JSON.stringify({
    email,
    firstname,
    lastname,
    password,
    mobile: `966${mobile}`,
    profile_picture: 'unknown',
    dateOfBirth: '2001-01-01',
    gender: '1',
  });
  const config = {
    method: 'post',
    url: '/customer/register/account/create',
    headers: {
      Authorization: `Bearer ${handshake}`,
    },
    data,
  };
  try {
    const req = await axios(config);
    return req;
  } catch (error) {
    return error;
  }
};

// 03 --> reset password: 1--> Send otp email api
const resetToken = async (handshake, email) => {
  const data = JSON.stringify({ email });
  const config = {
    method: 'post',
    url: '/customer/emailResetToken',
    headers: {
      Authorization: `Bearer ${handshake}`,
    },
    data,
  };
  try {
    const req = await axios(config);
    return req;
  } catch (error) {
    return error;
  }
};

// 03 --> reset password: 2--> Send otp to user email api
// TODO need to check backend required admin token or we should send from our side
const sendOtpToEmail = async (handshake, receiver, name, otp) => {
  const data = JSON.stringify({ receiver, name, otp });
  const config = {
    method: 'post',
    url: '/email/otp',
    headers: {
      Authorization: `Bearer ${handshake}`,
      admin: `Bearer ${process.env?.NEXT_PUBLIC_ADMIN_TOKEN}`,
    },
    data,
  };

  try {
    const req = await axios(config);
    return req;
  } catch (error) {
    return error;
  }
};

// 03 --> reset password: 3--> verify otp and change new password api
const resetNewPassword = async (
  handshake,
  email,
  newPassword,
  confirmPassword,
  resetToken
) => {
  const data = JSON.stringify({
    email,
    newPassword,
    confirmPassword,
    resetToken,
  });
  const config = {
    method: 'post',
    url: '/customer/forgetPassword',
    headers: {
      Authorization: `Bearer ${handshake}`,
    },
    data,
  };
  try {
    const req = await axios(config);
    return req;
  } catch (error) {
    return error;
  }
};

export { logIn, register, resetToken, sendOtpToEmail, resetNewPassword };
